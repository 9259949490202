
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component({
  components: {
    'pages-image-picker': () => import('./pages-image-picker.vue'),
  },
})
export default class PageEditorImage extends Vue {
  @Prop({ required: true })
  private readonly name: string;

  @Prop({ required: true })
  private readonly title: string;

  @Prop({ required: false, default: true })
  private readonly showGallery: boolean;

  @Prop({ required: false, default: '' })
  private readonly defaultValue: string;

  private value = '';

  private onImageChange(url: string) {
    this.value = url;
    this.$modal.hide(this.name);
  }

  private close() {
    this.$modal.hide(this.name);
  }

  mounted() {
    this.value = this.defaultValue;
  }

  @Watch('value')
  private onValueChange(value: string) {
    this.$emit('change', value);
  }
}
